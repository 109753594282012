import Vue from "vue";
import api from "@/axios.js";

const state = {
  customers: [],
  singleCustomers: [],
  selectedCustomers: [{ ID: 0, code: "All", name: "All" }],
  selectedCustomer: {},
  whiteListUrl: [
    "billing",
    "ar_payment_payment_received",
    "payment",
    "create-credit-note",
    "invoice-exchange",
    "ar-payment",
  ],
  customerAllOption: { ID: 0, code: "All", name: "All" },
};

const mutations = {
  setCustomers(state, payload) {
    state.customers = JSON.parse(JSON.stringify(payload));
    console.log("cust", state.customers);
  },
  setSelectedCustomers(state, payload) {
    state.selectedCustomers = JSON.parse(JSON.stringify(payload));
  },
  setSelectedCustomer(state, payload) {
    state.selectedCustomer = JSON.parse(JSON.stringify(payload));
  },
  setSingleCustomers(state, payload) {
    state.singleCustomers = JSON.parse(JSON.stringify(payload));
  },
  resetSelectedCustomers(state) {
    state.selectedCustomers = [state.customerAllOption];
  },
  resetState(state) {
    state.selectedCustomers = [state.customerAllOption];
    state.selectedCustomer = {};
  },
};

const getters = {
  getSelectedCustomersCodes(state) {
    return state.selectedCustomers.map((customer) => customer.code);
  },
  getSelectedCustomerCode(state) {
    return state.selectedCustomer.code;
  },
  getSelectedCutomerIDs(state) {
    console.log("state.selectedCustomers", state.selectedCustomers);
    if (state.selectedCustomers.some((d) => d.ID === 0)) {
      return [];
    }
    return state.selectedCustomers.map((customer) => customer.ID);
  },
  getSelectedCustomerID(state) {
    return state.selectedCustomer.ID;
  },
  getCustomersLength(state) {
    return state.customers.length;
  },
};

const actions = {
  async getCustomers({ commit }, params) {
    showLoading();
    try {
      const response = await api.get("/api/v1/master/customer/options", {
        params,
      });
      commit("setCustomers", [...response.data, state.customerAllOption]);
      commit("setSingleCustomers", response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
      hideLoading();
    } finally {
      hideLoading();
    }
  },
  async getCustomerByCopyPaste({ commit }, params) {
    showLoading();
    try {
      const response = await api.get("/api/v1/master/customer/options", {
        params,
      });
      commit("setCustomers", response.data);
      commit("setSingleCustomers", response.data);
    } catch (error) {
      console.log(error);
      hideLoading();
    } finally {
      hideLoading();
    }
  },
  async getAllCustomers({ commit }) {
    showLoading();
    try {
      const response = await api.get("/api/v1/master/customer/options");
      commit("setCustomers", [state.customerAllOption, ...response.data]);
      commit("setSingleCustomers", response.data);
    } catch (error) {
      console.log(error);
      hideLoading();
    } finally {
      hideLoading();
    }
  },
};

function showLoading() {
  Vue.prototype.$vs.loading();
}

function hideLoading() {
  Vue.prototype.$vs.loading.close();
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
